.battery-cell{
    padding: 5px 5px;
    .soc-flo{
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        // transform: translateX(100%);
        color: #333;
        text-align: center;
        line-height: 32px;
    }
    .bat-name{
        float: left;
        width: 42px;
        overflow: hidden;
        height: 32px;
        line-height: 32px;
        background-color: #E6F7FF;
        font-size: 14px;
        color: #262626;
        border-radius: 4px;
        border: 1px solid #BFBFBF;
        box-sizing: border-box;
        // div{
        //     height: 20px;
        // }
    }
    .batt-border{
        width: 200px;
        height: 30px;
        margin-left: 48px;
        text-align: left;
        border: 2px solid #BFBFBF;
        border-radius: 4px;
        position: relative;
    }
    .batt-border::after{
        content: "";
        display: inline-block;
        width: 6px;
        height: 12px;
        background: #BFBFBF;
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
    }
    .batt-soc{
        height: 100%;
        background: linear-gradient(90deg, #1890FF 0%, #52C41A 100%);
        display: inline-block;
    }
    .batt-soh{
        margin-top: 1px;
        height: 13px;
        background: forestgreen;
        font-size: 10px;
    }
    .unit-ceil{
        display: inline-block;
        height: 100%;
        border: 1px solid #FFF;
        box-sizing: border-box;
    }

}