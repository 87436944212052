.tc{
    text-align: center;
}
.mb20{
    margin-bottom: 20px;
}
.num-clus{
    height: 6px;
    text-align: center;
    font-size: 12px;
    // transform: scale(.8);
    position: relative;
    top: -7px;
}
.warn-unit{
    color: red;
}
.card-border{
    border: solid 2px #999 ;
}
.battery-page{
   
    .same-bloc{
        width: 44px;
        height: 30px;
    }
    .block-com0{
        background: linear-gradient(270deg, #75E4CA 0%, #1890FF 100%);
    }
    .block-com1{
        background: #FAAD14;
    }
    .block-com2{
        background: #FF4D4F;
    }
   
    .p10{
        padding: 10px;
    }
    .line-divide{
        border-bottom: 1px solid #BFBFBF ;
        text-align: center;
        .bor-s-clu{
            padding: 0 6px;
            // border: solid 1px #BFBFBF;
        }
    }
    .clu-border{
        border-right: 1px solid #BFBFBF;
    }
    .flex{
        display: flex;
    }
    .flex-1{
        flex: 1;
    }
    .flex-400{
        width: 400px;
    }
    .tl{
        text-align: left;
    }
    .tr{
        text-align: right;
    }
    .switch-head{
        padding-left: 40px;
        color: #3D3D3D;
        font-size: 12px;
    }
    .mr20{
        margin-right: 20px;
    }
    .com-list-title{
        width: 354px;
        height: 38px;
        border-radius: 8px 8px 0px 0px;
        font-size: 24px;
        color: #FFFFFF;
        padding-left: 20px;
    }
    .alarm-list{
        background: #FAAD14;
    }
    .check-time{
        background: #FF4D4F;
    }
    .sim-case{
        background: #1890FF; 
    }
    .ca-transf{
        transform: translateY(-12px);
        .content-detail{
            height: 160px;
            padding: 8px 16px;
        }
    }
}