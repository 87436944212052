.ant-card-body{
    padding: 12px !important;
}
.flex{
    display: flex;
}
.flex-20{
    width: 20px;
}
.flex-1{
    flex: 1;
}
.tit-bor{
    height: 57px;
    line-height: 54px;
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 20px;
}
.divi-tit{
    background: #FAFAFA;
    width: 20px;
    height: 57px;
    float: left;
    text-align: center;
    .div-top{
        margin-top: 15px;
    }
}
.line-h5{
    line-height: 5px;
}
.dr-shouyi{
    color:  #FA8C16;
}
.current-kp{
    color: #1890FF;
}
.font-nor{
    color: #3D3D3D;
}
.home-page{
    .yc-bat{
        color: #F5222D;
    }
  
   
    .tit-bac{
        background: #E6F7FF;
        overflow: hidden;
        height: 36px;
    }
    .card-pqab{
        float: left;
        line-height: 27px;
        color: #3D3D3D;
        padding-left: 16px;
    }
    .left-line{
        border-left: 1px solid #000;
    }
    .tc{
        text-align: center;
    }
    .card-style{
        margin-left: 20px;
    }
    .mb20{
        margin-bottom: 20px;
    }
    
   
    .tr-run{
        line-height: 36px;
        text-align: right;
        margin-right: 20px;
    }
    .ml40{
        margin-left: 40px;
    }
    .run-state0{
        color: #52C41A;
    }
    .run-state1{
        color:  #FF4D4F
    }
    .run-model{
        width: 24px;
        background: #E6F7FF;
        border: 1px solid #BFBFBF;
        border-radius: 4px;
        height: 32px;
        line-height: 16px;
        .div{
            height: 16px;
        }
    }
 
   
    .tc{
        text-align: center;
    }
    .echart-title{
        font-size: 16px;
        color: #3D3D3D;
        font-weight: 500;
    }
}