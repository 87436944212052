.flex-30{
    width: 30px;
}
.pt5{
    padding-top: 5px;
}
.fw500{
    font-weight: 500;
}
.page-lab-detail{
    .acc-det-titl{
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    padding-bottom: 20px;
}