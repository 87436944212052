.ml40{
    margin-left: 40px;
}
.f32{
    font-size: 32px;
}
.f30{
    font-size: 30px
}
.mr40{
    margin-right: 40px;
}
.l70{
    line-height: 70px;
}
.mr30{
    margin-right: 30px;
}
.l-l-back{
    border-radius: 4px;
    background: #24262c;
    margin-bottom: 15px;
}
.siteDetail-page{
    padding: 20px;
    height: 100vh;
    background: #1b1c1e;
    overflow: auto;
    box-sizing: border-box;
    .det-button{
        width: 200px;
        height: 56px;
        line-height: 56px;
        background: #1890FF;
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
        color: #FFFFFF;
    }
    .ru-c-item{
        height: 70px;
        width: 370px;
        margin-bottom: 20px;
        color: #E0E6F1;
        box-sizing: border-box;
        padding-left: 20px;
    }
   
    .h-40p{
        height: 40%;
    }
    .h-60p{
        height: 60%;
    }
    .h-100p{
        height: 94%;
    }
    .card-t{
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 20px;
    }
    .des-t{
        font-size: 16px;
        color: #E0E6F1;
        font-weight: 500;
    }
    .des-d{
        font-size: 16px;
        color: #E0E6F1;
        font-weight: 400;
    }
}
.l32{
    line-height: 32px;
}