
.from-modal-c{
}
.page-library{
   
    .op-butt{
        margin-bottom: 20px;
        text-align: right;
    }
}
.mb10{
    margin-bottom: 10px;
}
.mt5{
    margin-top: 5px;
}
.cur{
    cursor: pointer;
}
.ml100{
    margin-left: 100px;
}
.ser-button{
    position: absolute;
    right: 20px;
    bottom: 40px;
}
.file-table-cell{
    max-width: 200px;
    white-space: nowrap;
    cursor: pointer;
    margin: auto;
}
.file-item-cell{
    text-overflow: ellipsis;
    overflow: hidden;
}
.max-200{
    max-width: 250px;
    max-height: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}