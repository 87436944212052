.com-datepic{
    .slider-con{
        display: flex;
        .flex-width{
            width: 100px;
            color: #ccc;
        }
        .flex-1{
            flex: 1;
        }
    }
}