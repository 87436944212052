.com-bat-cluster{
   
    .soc{
        // height: 80%;
        // border-bottom: 1px solid #BFBFBF;
        height: 100%;
        position: relative;
    }
    .ab-font{
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        font-size: 8px;
        line-height: 28px;
        
    }
    .content-c{
        width: 100px;
        height: 28px;
        border: 2px solid #BFBFBF;
        // margin-bottom: 5px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        ::after{
            content: "";
            display: inline-block;
            position: absolute;
            width: 6px;
            height: 12px;
            background:#BFBFBF ;
            right: -6px;
            top: 50%;
            transform: translateY(-50%);
        }
      
        .soc-con{
            height: 100%;
            background: linear-gradient(270deg, #75E4CA 0%, #1890FF 100%);
        }
    }
    @media (max-width: 1500px) {
        .content-c{
            width: 50px;
        }
    }
    @media (min-width: 1500px) and (max-width: 1800px) {
        .content-c{
            width: 70px;
        }
    }

}
@media (max-width:1500px) {
    .bat-all-b{
        transform: scale(0.8);
    }
    
}
@media (max-width:1300px) {
    .bat-all-b{
        transform: scale(0.6);
    }
    
}