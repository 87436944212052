.yun-com-head {
    height: 76px;
    line-height: 76px;
    background: #24262c;
    color: #fff;

    .p-font {
        font-size: 32px;
    }

    .time-show {
        float: right;
        text-align: left;
        width: 108px;
        line-height: initial;
        margin-top: 12px;
        margin-right: 20px;

        .current-time {
            font-size: 28px;
        }
    }
}

.pl10 {
    padding-left: 10px;
}

.pr10 {
    padding-right: 10px;
}

.tab-xmxx {
    color: #bfbfbf;
    padding: 0 20px;
    width: 100%;
    font-size: 12px;

    tbody {
        tr {
            :nth-child(1) {
                max-width: 76px;
            }
        }
    }
}

.tr-line {
    position: relative;
    height: 40px;
}

.tr-line::after {
    position: absolute;
    bottom: 0;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    background: #bfbfbf;
}

.flex-440 {
    width: 440px;
    height: 100%;
}

.det-box {
    height: calc(100% - 120px);
    ;
}

.summarize-pageO {
    height: 100vh;
    overflow: hidden;
    position: relative;

    .sum-spac {
        .sum-item {
            background: #24262c;
            width: 200px;
            box-sizing: border-box;
            padding-top: 15px;
            height: 100px;
            text-align: center;

            .stat-des {
                color: #fff;
            }
        }

        .unit-font {
            font-size: 12px;
            color: #fff;
        }
    }

    .card-t {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 20px;
    }


    .l-head {
        border-top: 4px solid #0092fa;
    }

    .r-head {
        border-top: 4px solid #59c336;
    }

    .l-l-back {
        border-radius: 4px;
        background: #24262c;
        height: 33%;
        margin-bottom: 15px;
    }

    .l-l-1 {
        background: #24262c;
    }

    .l-l-2 {
        background: #24262c;
    }

    .l-l-3 {
        background: #24262c;
    }
}
.t-time-show{
    position: absolute;
    right: 20px;
    z-index: 1;
    margin-top: 15px;
    padding-right: 10px;
    background-color: #006277;
    height: 35px;
    width: 400px;
    line-height: 35px;
}

.he-boxO {
    position: relative;
    text-align: center;
    margin-top: -7px;
    margin-left: -15px;
    width: calc(100% + 28px);
    .he-f-d {
        font-size: 36px;
        font-weight: bold;
        background: linear-gradient(180deg, #FFFFFF 0%, #67D3DB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        position: absolute;
        top: 0;
        line-height: 72px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .tc-fot-data {
        position: relative;
       
       
    }
    .he-tit {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        
    }
}