.mt10{
    margin-top: 10px;
}
.bat-detail-con{
    .ant-divider{
        margin: 10px 0;
    }
    .his-acci-cont{
        padding: 5px 10px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
    }
    .flex{
        display: flex;
    }
    .flex-1{
        flex: 1;
    }
    .flex-156{
        width: 156px;
    }
    .his-acci{
        height: 24px;
        width: 146px;
        background: #F0F0F0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        line-height: 24px;
        padding-left: 8px;
    }
    .his-data{
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        font-weight: 500;
    }
    .modal-title-s{
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }
    .detail-inf-ti{
        background: #E0EAFF;
        height: 108px;
        text-align: center;
        border-right: 1px solid #fff;
        position: relative;
        .cont{
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            .l1{
                color: #606266;
                font-size: 14px;
            }
            .l2{
                font-size: 32px;
                color: #131414;
            }
        }
    }
}