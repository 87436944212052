.site-bat-page{
    padding: 20px;
    height: 100vh;
    background: #1b1c1e;
    overflow: auto;
    box-sizing: border-box;
    .dash-b-ch{
        height: 70px;
        border: 1px dashed #52C41A;
        margin-bottom: 20px;
        padding-left: 20px;
        color: #52C41A;
        text-align: center;
    }
}
.cfff{
    color: #fff;
}
.p20{
    padding: 20px;
}
.l50{
    line-height: 50px;
}
.ycxwh-f{
    background: #39332b;
    border-radius: 0px 0px 8px 8px;
    height: 50px;
    color: #fff;
}
.ycxwh-c{
    height: 46px;
    border-radius: 8px 8px 0px 0px;
    background: #8f6829;
    color: #fff;
    line-height: 46px;
    padding-left: 20px;
    font-weight: 500;
}
.ycxwh-b{
    height: 150px;
    background: #39332b;
    overflow: auto;

}
.p5{
    padding: 5px;
}
.tc{
    text-align: center;
}
.yc-box-y{
    width: 24px;
    height: 24px;
    background-color: #fbac33;
}
.p10{
    padding: 10px;
}
.pt0{
    padding-top: 0;
}
.pb0{
    padding-bottom: 0;
}
.colorY{
    color: #fbac33;
}
.colorR{
    color: #fe4d52;
}
.yc-box-r{
    width: 24px;
    height: 24px;
    background-color: #fe4d52;
}
.jxsjtx-h{
    height: 46px;
    border-radius: 8px 8px 0px 0px;
    background: #92393f;
    color: #fff;
    line-height: 46px;
    padding-left: 20px;
    font-weight: 500;
}
.jxs-b{
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #3a2a30;
    border-radius: 0px 0px 8px 8px;
}