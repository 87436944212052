.mb20{
    margin-bottom: 20px;
}
.tl{
    text-align: left;
}
.pb20{
    padding-bottom: 20px;
}
.page-data{
    .des{
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        margin-left: 45px;
    }
    .time-align{
        margin-left: 75px;
    }
    .export-title{
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .title{
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }
    .up-table{
        th{
            width: 75px;
            text-align: center;
            background: #F5F5F5;
            height: 48px;
        }
      
        .tab-li{
            border: none;
            background: #F5F5F5;
            height: 48px;
            text-align: center;
        }
    }
}