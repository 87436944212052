.tit-he-box{
    margin-top: -12px;
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -35px;
    height: 100px;
}
.bg-ima-si{
    position: absolute;
    left: -48px;
    right: 0;
    top: 0;
    bottom: 0;
}
.f-c-div{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    font-size: 30px;
    line-height: 2.2;
    font-weight: bold;

    background: linear-gradient(180deg, #FFFFFF 0%, #67D3DB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.he-box-l{
    position: relative;
}

.yun-com-head {
    height: 76px;
    line-height: 76px;
    background: #24262c;
    color: #fff;

    .p-font {
        font-size: 32px;
    }

    .time-show {
        float: right;
        text-align: left;
        width: 108px;
        line-height: initial;
        margin-top: 12px;
        margin-right: 20px;

        .current-time {
            font-size: 28px;
        }
    }
}

.pl10 {
    padding-left: 10px;
}

.pr10 {
    padding-right: 10px;
}
.circle-normal{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #45A9B0;
    display: inline-block;
}
.circle-warn{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #FAAD14;
    display: inline-block;
}


.tab-xmxx {
    padding: 0 20px;
    width: 100%;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    border-collapse:collapse;
    margin-right: 10px;
    thead{
        background: rgba(0, 98, 119, 0.7);
    }
    tbody {
        tr {
            :nth-child(1) {
                max-width: 43px;
            }
        }
    }
}

.tr-lines {
    height: 40px;
}

// .tr-line::after {
//     position: absolute;
//     bottom: 0;
//     content: "";
//     width: 100%;
//     height: 1px;
//     left: 0;
//     background: #bfbfbf;
// }

.flex-440 {
    width: 440px;
    height: 100%;
}

.det-box {
    height: calc(100% - 120px);
}

.summarize-page {
    height: 100vh;
    overflow: hidden;
    position: relative;

    .sum-spac {
        .sum-item {
            background: #24262c;
            width: 200px;
            box-sizing: border-box;
            padding-top: 15px;
            height: 100px;
            text-align: center;

            .stat-des {
                color: #fff;
            }
        }

        .unit-font {
            font-size: 12px;
            color: #fff;
        }
    }

    .card-t {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 20px;
    }


    .l-head {
        border-top: 4px solid #0092fa;
    }

    .r-head {
        border-top: 4px solid #59c336;
    }

    .l-l-back {
        border-radius: 4px;
        background: #24262c;
        height: 33%;
        margin-bottom: 15px;
    }

    .l-l-1 {
        background: #24262c;
    }

    .l-l-2 {
        background: #24262c;
    }

    .l-l-3 {
        background: #24262c;
    }
}
.t-time-shows{
    position: absolute;
    right: 20px;
    z-index: 1;
    top: 0;
    margin-top: 15px;
    padding-right: 10px;
    height: 35px;
    width: 220px;
    text-align: left;
    line-height: 35px;
}

.he-box {
    position: relative;
    text-align: center;
    .tc-fot-data {
        position: relative;
        margin-top: -65px;
        .he-f-d {
            font-size: 36px;
            font-weight: bold;
            background: linear-gradient(180deg, #FFFFFF 0%, #67D3DB 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            position: relative;
            line-height: 72px;
        }
    }
    .he-tit {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        
    }
}
.site-left-panel{
    position: absolute;
    left: 0;
    width: 420px;
}
.site-right-panel{
    position: absolute;
    width: 420px;
    right: 0;
}
.sit-ite-b-t{
    width: 410px;
    height: 44px;
    text-align: center;
    background: linear-gradient(0deg, rgba(70,172,176,0.50) 0%, rgba(80,174,181,0.00) 55%, rgba(69,171,175,0.60) 100%);
    border-radius: 12px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 44px;
    margin-bottom: 10px;
}
.sit-ite-f-t{
    height: 2px;
    width: 410px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: linear-gradient(270deg, rgba(70,172,176,0.00) 0%, #45ABAF 52%, rgba(80,174,181,0.00) 100%);
}
.z-b-sit{
    height: calc(30vh - 80px);
    margin-right: 10px
}
.op-half-con{
    width: 60%;
    height: 91%;
    background: rgba(0, 0, 0, 0.2);
    margin: auto;
    position: relative;
}
.op-font-f{
    font-size: 14px;
    color: #F0F0F0;
    text-align: center;
}
.lin-zjrl-bar{
    position: absolute;
    bottom: 0;
    display: inline-block;
    border-radius: 2px;
}
.bot-nosolid{
    border-bottom: 1px dashed #fff;
}
