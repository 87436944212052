.ml20{
    margin-left: 20px;
}
.pl40{
    padding-left: 40px;
}
.ml40{
    margin-left: 40px;
}
.w250{
    width: 250px;
}
.flex-480{
    width: 480px;
}
.pos-rel{
    position: relative;
}
.adjust-page{
    .des-title-echa{
        color: #3D3D3D;
        font-size: 16px;
        font-weight: 500;
    }
    .des-f-ad{
        position: absolute;
        right: 480px;
        width: 250px;
        top: 2px;
        line-height: initial;
        font-size: 14px;
        color: #262626;
    }
    .tr{
        text-align: right;
    }
    .date-fixed{
        position: fixed;
        bottom: 0;
        width: calc(100% - 80px) ;
        height: 70px;
        z-index: 1;
        box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        background: #fff;
        padding: 20px;
    }
    .line-mag{
        padding-bottom: 120px;
    }

}