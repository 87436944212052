.header-tab{
    text-align: center;
    overflow: hidden;
    background-color: #fff;
    .current{
        color: #177DDC;
    }
    .current::after{
        width: 100%;
        height: 2px;
        background-color: #177DDC;
        display: block;
        content: "";
        transform: translateY(-20px);
    }
    .title-ic{
        float: left;
        color: #767676;
        font-size: 40px;
        line-height: 60px;
        margin-left: 40px;
    }
    .tab-ul{
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        margin-right: 20px;
        text-align: right;
    }

    .com-title{
        margin-left: 40px;
        font-size: 18px;
    }
}